import * as React from 'react';
import Home from './components/home';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import PrivacyPolicy from './components/privacy-policy';
import TermsAndConditions from './components/terms-and-conditions';
import AboutUs from './components/about-us';
import ContactUs from './components/contact-us';

function App() {
  return (
    // <React.Fragment>
    //   <CssBaseline />
    <Routes>
      <Route path="/" element={<Home />}>
        {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
        {/* <Route path="*" element={<NoMatch />} /> */}
      </Route>
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
    </Routes>
    // </React.Fragment>
  );
}

export default App;
