import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <Grid
      container
      spacing={5}
      sx={{
        borderTop: 1,
        borderColor: '#ffdab3',
        backgroundColor: '#ffeee6',
        mt: 20,
        px: { sm: 8, xs: 2 },
        pb: 4,
      }}
    >
      <Grid item spacing={5} md={3} xs={12} container>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              borderColor: 'black',
              borderRadius: 10,
              py: 0.5,
              px: 2,
              boxShadow: 4,
              backgroundColor: 'white',
              width: 'fit-content',
            }}
          >
            <img
              src="/cart-proof.png"
              alt="Logo"
              style={{ height: 45, marginLeft: 2 }}
            />
            <Typography
              component="div"
              sx={{
                borderWidth: 1,
                fontWeight: 'bold',
                color: 'grey',
                fontSize: 18,
                display: { xs: 'none', sm: 'block' },
              }}
            >
              CartProof
            </Typography>
          </Box>
        </Grid>
        <Grid item md={6} sx={12}>
          <Typography
            component="p"
            sx={{
              fontWeight: 'bold',
              fontFamily: ['Avenir Next'],
              pb: 1,
              fontSize: 17,
            }}
          >
            Support
          </Typography>

          <Typography
            variant="p"
            style={{
              fontFamily: ['Avenir Next'],
              // fontWeight: 500,
              // color: '#808080',
              fontSize: 17,
            }}
          >
            support@cartproof.com
          </Typography>
          <Typography
            variant="body2"
            style={{
              fontFamily: ['Avenir Next'],
              // fontWeight: 500,
              // color: '#808080',
              fontSize: 17,
              lineHeight: 1,
            }}
          >
            +919028775154
          </Typography>
        </Grid>
      </Grid>
      <Grid item spacing={5} md={9} xs={12} container marginTop={{ md: 2 }}>
        <Grid item md={3} sx={{ display: { xs: 'none', sm: 'block' } }} />
        <Grid item md={3} sx={{ display: { xs: 'none', sm: 'block' } }} />
        <Grid item md={3} xs={12}>
          <Typography
            component="h6"
            sx={{
              fontWeight: 'bold',
              fontFamily: ['Avenir Next'],
              pb: 1,
              fontSize: 18,
              color: '#e68a00',
            }}
          >
            Learn More
          </Typography>
          <List>
            <ListItem sx={{ paddingLeft: 0 }}>
              <Typography
                variant="body1"
                component={Link}
                sx={{
                  color: 'grey',
                  textTransform: 'none',
                  fontSize: 16,
                  fontWeight: '500',
                  fontFamily: ['Avenir Next'],
                  px: 0,
                  textDecoration: 'none',
                  backgroundColor: 'transparent',
                  '&:hover': {
                    bgcolor: 'transparent',
                    color: 'orange',
                  },
                }}
                onClick={() => {}}
              >
                Youtube
              </Typography>
            </ListItem>
          </List>
        </Grid>
        <Grid item md={3} xs={12}>
          <Typography
            component="h6"
            sx={{
              fontWeight: 'bold',
              fontFamily: ['Avenir Next'],
              pb: 1,
              fontSize: 18,
              color: '#e68a00',
            }}
          >
            Company
          </Typography>
          <List>
            <ListItem sx={{ paddingLeft: 0 }}>
              <Typography
                variant="body1"
                component={Link}
                to={'/privacy-policy'}
                sx={{
                  color: 'grey',
                  textTransform: 'none',
                  fontSize: 16,
                  fontWeight: '500',
                  fontFamily: ['Avenir Next'],
                  px: 0,
                  textDecoration: 'none',
                  backgroundColor: 'transparent',
                  '&:hover': {
                    bgcolor: 'transparent',
                    color: 'orange',
                  },
                }}
              >
                Privacy policy
              </Typography>
            </ListItem>
            <ListItem sx={{ paddingLeft: 0 }}>
              <Typography
                variant="body1"
                component={Link}
                to={'/terms-and-conditions'}
                sx={{
                  color: 'grey',
                  textTransform: 'none',
                  fontSize: 16,
                  fontWeight: '500',
                  fontFamily: ['Avenir Next'],
                  px: 0,
                  textDecoration: 'none',
                  backgroundColor: 'transparent',
                  '&:hover': {
                    bgcolor: 'transparent',
                    color: 'orange',
                  },
                }}
                onClick={() => {}}
              >
                Terms and conditions
              </Typography>
            </ListItem>
            <ListItem sx={{ paddingLeft: 0 }}>
              <Typography
                variant="body1"
                to={'/contact-us'}
                component={Link}
                sx={{
                  color: 'grey',
                  textTransform: 'none',
                  fontSize: 16,
                  fontWeight: '500',
                  fontFamily: ['Avenir Next'],
                  px: 0,
                  textDecoration: 'none',
                  backgroundColor: 'transparent',
                  '&:hover': {
                    bgcolor: 'transparent',
                    color: 'orange',
                  },
                }}
                onClick={() => {}}
              >
                Contact us
              </Typography>
            </ListItem>
            <ListItem sx={{ paddingLeft: 0 }}>
              <Typography
                variant="body1"
                to={'/about-us'}
                component={Link}
                sx={{
                  color: 'grey',
                  textTransform: 'none',
                  fontSize: 16,
                  fontWeight: '500',
                  fontFamily: ['Avenir Next'],
                  px: 0,
                  textDecoration: 'none',
                  backgroundColor: 'transparent',
                  '&:hover': {
                    bgcolor: 'transparent',
                    color: 'orange',
                  },
                }}
                onClick={() => {}}
              >
                About us
              </Typography>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Grid>
  );
}
