import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  transition: 'padding 0.3s ease',
}));

const drawerWidth = 240;
const navItems = ['About', 'Contact'];

export default function Header(props) {
  const { window: _window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const theme = useTheme();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const [padding, setPadding] = React.useState('40px 0px'); // Initial padding
  const [scrolled, setScrolled] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      if (currentScrollTop > 0) {
        setPadding('10px 0px'); // Reduce padding on scroll down
        setScrolled(true);
      } else {
        setPadding('40px 0px'); // Increase padding on scroll up
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleNavClick = (navDest) => {
    if (navDest === 'About') {
      navigate('/about-us');
    } else {
      navigate('/contact-us');
    }
  };

  const drawer = (
    <Box sx={{ textAlign: 'center' }}>
      <Typography
        variant="h6"
        sx={{ my: 2, fontWeight: 'bold', color: 'grey' }}
      >
        CartProof
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton
              sx={{ textAlign: 'center' }}
              onClick={() => handleNavClick(item)}
            >
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    _window !== undefined ? () => _window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <StyledAppBar
        component="nav"
        sx={{
          backgroundColor: 'white',
          padding,
          justifyContent: 'center',
          // position:'relative',
          [theme.breakpoints.only('xs')]: {
            padding: 0,
            py: 1,
          },
          ...(scrolled && { borderBottom: 1, borderColor: '#D3D3D3' }),
        }}
        elevation={0}
      >
        <Toolbar>
          <Grid
            container
            // spacing={2}
            px={{ md: 10, xs: 2 }}
            sx={{ flexDirection: { sm: 'row', xs: 'row-reverse' } }}
          >
            <Grid item xs={3} sx={{ alignItems: 'center' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  //   border: 1,
                  borderColor: 'black',
                  borderRadius: 10,
                  py: 0.5,
                  px: 2,
                  boxShadow: 4,
                  width: 'fit-content',
                  [theme.breakpoints.only('xs')]: {
                    py: 0,
                  },
                }}
              >
                <img
                  src="/cart-proof.png"
                  alt="Logo"
                  style={{ height: 50, marginLeft: 2 }}
                />
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    borderWidth: 1,
                    fontWeight: 'bold',
                    color: 'grey',
                    display: { xs: 'none', sm: 'block' },
                  }}
                >
                  CartProof
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={9}
              sx={{ display: { sm: 'none' }, alignContent: 'center' }}
            >
              <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ display: { sm: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs={9} sx={{ display: { xs: 'none', sm: 'block' } }}>
              <Box
                sx={{ display: { xs: 'none', sm: 'block' }, float: 'right' }}
              >
                {navItems.map((item) => (
                  <Button
                    key={item}
                    disableRipple
                    onClick={() => handleNavClick(item)}
                    sx={{
                      color: 'black',
                      fontSize: 18,
                      fontWeight: '500',
                      textTransform: 'none',
                      fontFamily: ['monospace', 'serif'].join(','),
                      pl: 8,
                      backgroundColor: 'transparent',
                      '&.MuiButtonBase-root:hover': {
                        bgcolor: 'transparent',
                        color: 'orange',
                      },
                    }}
                  >
                    {item}
                  </Button>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </StyledAppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main">
        <Toolbar
          sx={{
            m: 6,
            [theme.breakpoints.only('xs')]: {
              m: 1,
              //   py:5
            },
          }}
        />
      </Box>
    </Box>
  );
}
