import React from 'react';
import { Container, Typography, Box, Link} from '@mui/material';

function ContactUs() {
  return (
    <>
      <Container maxWidth="lg">
        <Box
          sx={{
            mt: 5,
            p: 3,
            bgcolor: '#fff',
            boxShadow: 3,
            borderRadius: 2,
          }}
        >
          <Typography
            variant="h4"
            component="h1"
            align="center"
            gutterBottom
          >
            Contact Us
          </Typography>

          {/* Support Section */}
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
              Support
            </Typography>
            <Typography variant="body1" gutterBottom>
              <Link href="mailto:support@cartproof.com" underline="hover">
                support@cartproof.com
              </Link>
            </Typography>
            <Typography variant="body1">
              <Link href="tel:+91 9028775154" underline="hover">
                +91 9028775154
              </Link>
            </Typography>
          </Box>

          {/* Registered Office Address Section */}
          <Box>
            <Typography variant="h6" component="h2" gutterBottom>
              Registered Office Address
            </Typography>
            <Typography variant="body1">
              CARTPROOF
            </Typography>
            <Typography variant="body1">
              C-1008 Yashone, Pathak Road, Maan, Hinjewadi Phase-1, Pune,
            </Typography>
            <Typography variant="body1">
              411057
            </Typography>
          </Box>
        </Box>

        {/* Footer */}
        <Box
          sx={{
            textAlign: 'center',
            mt: 4,
            p: 2,
            bgcolor: '#2b7a78',
            color: '#fff',
          }}
        >
          <Typography variant="body2">
            &copy; 2024 Cart Proof. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </>
  );
}

export default ContactUs;
