import React from 'react';
import { Container, Typography, Box } from '@mui/material';

function AboutUs() {
  return (
    <>
      <Container maxWidth="lg">
        <Box 
          sx={{ 
            mt: 5, 
            p: 3, 
            bgcolor: '#fff', 
            boxShadow: 3, 
            borderRadius: 2 
          }}
        >
          <Typography 
            variant="h4" 
            component="h1" 
            align="center" 
            gutterBottom
          >
            About Us
          </Typography>
          <Typography 
            variant="body1" 
            paragraph
            sx={{ fontSize: 18, lineHeight: 1.6, color: '#555' }}
          >
            <strong style={{ color: '#2b7a78' }}>Cart Proof</strong> was created with a vision to revolutionize the way consumers make purchasing decisions by offering an innovative solution that bridges the gap between online shopping and real-world product experience. Our app enables users to check if someone nearby already owns the product they are interested in, allowing them to see and experience the product firsthand before making a purchase. By doing so, users can make informed decisions, especially for high-value items such as expensive setups, and understand the product's performance in a real-world environment.
          </Typography>
          <Typography 
            variant="body1" 
            paragraph
            sx={{ fontSize: 18, lineHeight: 1.6, color: '#555' }}
          >
            Cart Proof also benefits product owners, who earn a share of the affiliate commission when users buy through platforms like Amazon or Flipkart after experiencing the product. Since its inception, Cart Proof has been driven by the idea of empowering consumers and creating a community where shared product experiences lead to smarter, more confident shopping choices.
          </Typography>
        </Box>
        <Box 
          sx={{ 
            textAlign: 'center', 
            mt: 4, 
            p: 2, 
            bgcolor: '#2b7a78', 
            color: '#fff' 
          }}
        >
          <Typography variant="body2">
            &copy; 2024 Cart Proof. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </>
  );
}

export default AboutUs;
