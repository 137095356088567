import Header from './header';
import Footer from './footer';
import Body from './body';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { Container } from '@mui/material';

function Home() {
  return (
    <Box>
      <CssBaseline />
      <Container maxWidth="xl">
      <Header />
      <Body />
      <Footer />
      </Container>
    </Box>
  );
}

export default Home;
